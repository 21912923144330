import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Header from "components/nautilus/Header";
import HomepageCard from "components/nautilus/HomepageCard";
import { teamProducts } from "components/organisms/SidebarLayout/Sidebar";
import { serverUrl } from "network";
import { IntelligenceReportsAllInsightsPathname } from "pages/IntelligenceReports/IntelligenceReportsAllInsights";
import { Product, ProductTypeEnum } from "reactQuery/hooks/apiTypes";
import { useGetWhoami } from "reactQuery/hooks/pages/useSidebarLayout";
import { DocumentCompareUploadPathname } from "routes/DocumentCompare";
import { IndicatorFeedsAllFeedsPathname } from "routes/IndicatorFeeds";
import { PriorityInsightsPathname } from "routes/IntelligenceFeeds";

const adminCard = {
    description: "View and manage data.",
    href: `${serverUrl}/admin`,
    title: "Admin Site",
};
const reportsCard = {
    description: "Create and view Reports.",
    href: `/product-not-found`, // URL gets changed by the logic below when the page loads
    title: "Intelligence Platform",
};
const targetSurfacerCard = {
    description: "Search all discovered documents and webpages.",
    href: `/targets`,
    title: "Target Surfacer",
};

export const cardData = [adminCard, reportsCard, targetSurfacerCard];

export const createRedirectBasedOnProductType = (product: Product, teamId: number) => {
    let pathname: string;

    if (!product) return "/product-not-found";

    if (product.product_type === ProductTypeEnum.INTELLIGENCE_FEEDS) {
        pathname = PriorityInsightsPathname;
    } else if (product.product_type === ProductTypeEnum.INTELLIGENCE_REPORTS) {
        pathname = IntelligenceReportsAllInsightsPathname;
    } else if (product.product_type === ProductTypeEnum.INDICATOR_FEEDS) {
        pathname = IndicatorFeedsAllFeedsPathname;
    } else if (
        product.product_type === ProductTypeEnum.DASHBOARD &&
        product.path !== ""
    ) {
        pathname = product.path;
    } else if (product.product_type === ProductTypeEnum.DOCUMENT_COMPARE) {
        pathname = DocumentCompareUploadPathname;
    } else {
        return "/product-not-found"; // fallback
    }

    return `${pathname}?team_id=${teamId}`;
};

export const Home = () => {
    const getWhoamiQuery = useGetWhoami();

    const [cardDataState, setCardDataState] = useState(cardData);

    const { push } = useHistory();

    useEffect(() => {
        if (getWhoamiQuery.isSuccess) {
            const teamId = getWhoamiQuery.data.team.id;
            const firstProduct = teamProducts(getWhoamiQuery.data.team)?.[0];
            const isStaff = getWhoamiQuery.data.user.is_staff;

            const redirect = createRedirectBasedOnProductType(firstProduct, teamId);

            if (isStaff) {
                let newReportsCard = { ...reportsCard, href: redirect };
                let newCardData = [adminCard, newReportsCard, targetSurfacerCard];
                setCardDataState(newCardData);
            } else {
                if (redirect === "/product-not-found")
                    return push("/product-not-found");

                push(redirect);
            }
        }
    }, [getWhoamiQuery.isSuccess]);

    const cards = cardDataState.map((data, index) => (
        <HomepageCard
            description={data.description}
            href={data.href}
            key={index}
            title={data.title}
        />
    ));
    const body =
        getWhoamiQuery.isSuccess && getWhoamiQuery.data.user.is_staff ? (
            <Grid container spacing={4}>
                {cards}
            </Grid>
        ) : (
            <CircularProgress />
        );
    return (
        <div>
            <Header />
            <div style={{ padding: "32px" }}>{body}</div>
        </div>
    );
};

export default Home;
